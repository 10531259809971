#review {
  font-family: Georgia;
}

#head {
  display: flex;
  justify-content: space-around;
}

#head > div {
  flex: 1 1 0;
}

#info {
  line-height: 200%;
  padding-top: 5vw;
  padding-left: 5vw;

  font-size: 200%;
  color: black;
  word-spacing: 0.25em;
}

#score {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;

  background-color: white;
  border: black solid 2px;
  font-family: Garamond;
  font-size: 6vw;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 10vw;
  height: 10vw;
  border-radius: 50%;
}

#body {
  margin: 5vw 10vw;
  font-size: 200%;
  line-height: 150%;
  text-indent: 2em;
  white-space: pre-wrap;
}

#stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5vw;
  position: relative;
  height: 100%;
}

.image {
  width: 80%;
  border: black solid 2px;
  background-color: white;
  transition: 0.5s ease all;
}

.image:not(:first-child) {
  position: absolute;
}

.hide {
  opacity: 0;
}

.hide:hover {
  opacity: 1;
}
