#songs {
  overflow-x: hidden;
  background-color: black;
}

#background {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

#menu {
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  border-top: 7.5vh solid black;
  border-bottom: 7.5vh solid black;
  /* border-left: 33.3vh solid black; */
  z-index: 1;
}

.item {
  position: relative;
  text-align: center;
  font-size: 150%;
  height: 100%;
  width: 300px;
  border-left: 60px solid black;
  white-space: normal;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  font-family: "Raleway", Verdana;
  color: white;
  word-spacing: 0.25em;
  line-height: 1.5em;
}

.content {
  margin: 0 13%;
  overflow: auto;
  /* full height - cover - yt logo - space for yt logo*/
  height: calc(100% - 300px - 75px - 10px);
  mask-image: linear-gradient(to bottom, white 80%, transparent 100%);
}

.content::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.cover-f {
  max-height: 100%;
  max-width: 100%;
  /* border-bottom: 2px solid white; */
}

.cover {
  max-height: calc(70% - 4px);
  max-width: calc(70% - 4px);
  margin: 15% 15%;
  border: 4px solid white;
}

.tilt {
  transition: 1s ease all;
}

.tilt:hover {
  transform: rotateX(10deg) rotateY(-10deg);
  transform-origin: center;
  transition: transform 1s ease all;
}

.album {
  font-size: 90%;
  font-style: italic;
  color: lightgray;
}

.year {
  font-size: 80%;
  color: lightgray;
}

.yt_logo {
  filter: invert();
  height: 45px;
}
