#home {
  font-family: "Georgia";
  font-size: 275%;
  color: black;
  word-spacing: 0.25em;
  line-height: 3em;
  max-width: 50vw;
  margin: auto;
  margin-top: -9px;
  margin-bottom: -9px;
  padding: 25px;
  text-align: center;
  width: fit-content;
  border-left: 25px solid black;
  border-right: 25px solid black;
}

#title {
  text-decoration: underline;
}

#songs {
  text-decoration: underline;
  text-decoration-color: gray;
  text-decoration-thickness: 0.05em;
}

.link {
  text-decoration: none;
  color: black;
}

.link:visited {
  color: black;
}
